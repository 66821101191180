import axios from 'axios';
import { toggleAlertMessage } from '../action.js';
import createSignedRequest from './createSignedRequest';
import createUnsignedRequest from './createUnsignedRequest.js';
const { apiURL } = require('./apiURL');

const Types = {
  STRIPE_INTENT: 'PAYMENT_STRIPE_INTENT',
  STRIPE_GETTING_COUPON: 'PAYMENT_STRIPE_GETTING_COUPON',
  STRIPE_COUPON: 'PAYMENT_STRIPE_COUPON',
  STRIPE_LIST_INVOICES: 'PAYMENT_STRIPE_LIST_INVOICES',
  STRIPE_UPDATE_SUBSCRIPTION: 'PAYMENT_STRIPE_UPDATE_SUBSCRIPTION',
  PAYMENT_SELECT_PRODUCT: 'PAYMENT_SELECT_PRODUCT',
  PAYMENT_SELECT_PRICE: 'PAYMENT_SELECT_PRICE',
  PAYMENT_SHOW_MODAl: 'PAYMENT_SHOW_MODAl',
  PAYMENT_SUBSCRIPTION_PLAN: 'PAYMENT_SUBSCRIPTION_PLAN',
  PAYMENT_BILLING_DETAILS: 'PAYMENT_BILLING_DETAILS',
  SET_STRIPE_CUSTOMER_ID: 'PAYMENT_SET_STRIPE_CUSTOMER_ID',
  LIST_STRIPE_PAYMENT_METHODS: 'PAYMENT_LIST_STRIPE_PAYMENT_METHODS',
  UPDATE_STRIPE_SUBSCRIPTION: 'PAYMENT_UPDATE_STRIPE_SUBSCRIPTION',
  UPDATE_PRICING_BANDS: 'PAYMENT_UPDATE_PRICING_BANDS',
  LIST_STRIPE_SUBSCRIPTIONS: 'PAYMENT_LIST_STRIPE_SUBSCRIPTIONS',
  LIST_STRIPE_PRICING_TIERS: 'PAYMENT_LIST_STRIPE_PRICING_TIERS',
};

const selectProduct = (product) => ({
  type: Types.PAYMENT_SELECT_PRODUCT,
  product,
});

const selectPrice = (price) => ({
  type: Types.PAYMENT_SELECT_PRICE,
  price,
});

const subscriptionUpdated = (response) => ({
  type: Types.STRIPE_UPDATE_SUBSCRIPTION,
  payload: response,
});

export const updatingSubscription = (organisationRevenue) => {
  return async (dispatch, getState) => {
    const state = getState();

    console.log('updatingSubcription', organisationRevenue);

    const customerID = state.payment.stripe?.stripeCustomerID || '';

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + '/payment/stripe/subscription',
          JSON.stringify({ organisationRevenue, customerID }),
          { Organisation: String(state.currentOrganisation) }
        )
      );

      console.log('updatingSubscription', response);
      dispatch(subscriptionUpdated(response.data.subscription));
    } catch (e) {
      console.log('updatingSubscription Error:', e);
    }
  };
};

const showSubscriptionModal = (status) => ({
  type: Types.PAYMENT_SHOW_MODAl,
  status,
});

const subscriptionPlan = (subscriptionPlan) => ({
  type: Types.PAYMENT_SUBSCRIPTION_PLAN,
  subscriptionPlan,
});

const registrationBillingDetails = (billingDetails) => ({
  type: Types.PAYMENT_BILLING_DETAILS,
  billingDetails,
});

const gettingCoupon = (status) => ({
  type: Types.STRIPE_GETTING_COUPON,
  status,
});

const stripeCoupon = (stripeCoupon) => ({
  type: Types.STRIPE_COUPON,
  stripeCoupon,
});

const getStripeCoupon = (coupon) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { stripeAccountId } = state.whiteLabelAdmin || null;

    dispatch(gettingCoupon(true));

    fetch(apiURL + `/registration/stripe/promoCode?${stripeAccountId ? `stripeAccountId=${stripeAccountId}` : ``}`, {
      method: 'POST',
      body: JSON.stringify({ coupon }),
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch(stripeCoupon(response));
      })
      .catch((error) => console.log('Error Fetching Stripe Coupon', error));
  };
};

const stripeIntent = (response) => ({
  type: Types.STRIPE_INTENT,
  payload: response,
});

const createStripeCheckoutSession = (paymentMethod) => {
  return async (dispatch, getState) => {
    const state = getState();

    const { organisation: { industry, currency } = {} } = state.profile?.registrationDetails || {};
    const billingDetails = state.payment?.billingDetails;

    localStorage.setItem('Organisation', state.currentOrganisation);

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + '/payment/stripe/checkout-session',
          JSON.stringify({ paymentMethod, billingDetails: { ...billingDetails, industry, currency } }),
          { Organisation: String(state.currentOrganisation) }
        )
      );

      console.log('createStripeCheckoutSession', response);
      dispatch(stripeIntent(response.data));
    } catch (e) {
      console.log('createStripeCheckoutSession Error:', e);
      toggleAlertMessage(true, e.message, 'error');
    }
  };
};

const getStripeIntent = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const stripeCustomerId = state.payment.stripe?.stripeCustomerId || '';

    if (state.currentOrganisation) {
      try {
        const response = await axios(
          await createSignedRequest('GET', apiURL + `/payment/stripe/intent?customerId=${stripeCustomerId}`, null, {
            Organisation: String(state.currentOrganisation),
          })
        );

        console.log('getStripeIntent', response);

        const { client_secret } = response.data || {};
        dispatch(stripeIntent({ client_secret }));
      } catch (error) {
        console.log('getStripeIntent Error:', error);
      }
    }
  };
};

const listStripeInvoices = (invoices) => ({
  type: Types.STRIPE_LIST_INVOICES,
  payload: { invoices },
});

const getStripeInvoices = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const orgId = state.currentOrganisation;

    dispatch(listStripeInvoices({ invoices: [], loader: true }));

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/payment/stripe/billing', null, { Organisation: String(orgId) })
      );

      console.log('getStripeInvoices', response);

      const { error, invoices } = response.data || {};

      if (error) {
        dispatch(listStripeInvoices({ invoices: [], loader: false }));
        return dispatch(toggleAlertMessage(true, error, 'error'));
      }

      dispatch(listStripeInvoices({ invoices, loader: false }));
    } catch (e) {
      dispatch(toggleAlertMessage(true, 'There was problem while fetching your invoices.', 'error'));
      dispatch(listStripeInvoices({ invoices: [], loader: false }));
      console.log('getStripeInvoices Error:', e);
    }
  };
};

const sendUserOrderForm = (orgId) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/payment/stripe/orderForm', '{}', { Organisation: String(orgId) })
      );

      console.log('sendUserOrderForm', response);
    } catch (e) {
      console.log('sendUserOrderForm Error:', e);
    }
  };
};

const updatePricingBands = (pricingBands) => ({
  type: Types.UPDATE_PRICING_BANDS,
  pricingBands,
});

const savePricingBand = (band) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/admin/pricingBands', JSON.stringify({ band }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('savePricingBand', response);

      const { band: saveBand, error } = response.data || {};

      if (error) dispatch(toggleAlertMessage(true, 'User not authorised', 'error'));
      else if (saveBand) dispatch(updatePricingBands(saveBand));

      console.log('savePricingBand', response);
    } catch (e) {
      console.log('savePricingBand Error:', e);
    }
  };
};

const setStripeCustomerId = (response) => ({
  type: Types.SET_STRIPE_CUSTOMER_ID,
  response,
});

const getStripeCustomerId = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/payment/stripe/stripeCustomerId`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      const { stripeCustomerId } = response.data || {};

      dispatch(setStripeCustomerId(stripeCustomerId));

      return stripeCustomerId;
    } catch (err) {
      console.log('Error Getting Stripe Customer', err.message);
    }
  };
};

const listPaymentMethods = (paymentMethods, loader) => ({
  type: Types.LIST_STRIPE_PAYMENT_METHODS,
  paymentMethods,
  loader,
});

const getStripePaymentMethods = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const stripeCustomerId = state.payment?.stripe?.stripeCustomerId || '';

    dispatch(listPaymentMethods({}, true));
    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/payment/stripe/paymentMethods?customerId=${stripeCustomerId}`, '', {
          Organisation: String(state.currentOrganisation),
        })
      );

      const { error, paymentMethods, defaultPaymentMethod, subscriptions } = response.data || {};
      if (error) dispatch(toggleAlertMessage(true, error, 'error'));
      else dispatch(listPaymentMethods({ paymentMethods, defaultPaymentMethod, subscriptions }, false));
    } catch (err) {
      dispatch(listPaymentMethods([], false));
    }
  };
};

export const listSubscriptions = (subscriptions) => ({
  type: Types.LIST_STRIPE_SUBSCRIPTIONS,
  subscriptions,
});

const getSubscription = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const stripeCustomerId = state.payment?.stripe?.stripeCustomerId || '';

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/payment/stripe/subscriptions?customerId=${stripeCustomerId}`, '', {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getSubscription', response);

      dispatch(listSubscriptions(response.data.subscriptions));

      return response;
    } catch (err) {}
  };
};

const createStripeCustomer = (createIntent) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { firstName, lastName, email } = state.profile?.details || {};
    const { vatNumber } = state.organisation?.details || {};
    const customerDetails = { name: firstName + ' ' + lastName, email, vatNumber };

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + `/payment/stripe/createStripeCustomer`,
          JSON.stringify({ createIntent, customerDetails }),
          {
            Organisation: String(state.currentOrganisation),
          }
        )
      );

      const { stripeCustomerId, client_secret } = response.data || {};
      if (client_secret) dispatch(stripeIntent({ client_secret }));
      dispatch(setStripeCustomerId(stripeCustomerId));
      return response;
    } catch (err) {
      dispatch(setStripeCustomerId({ error: 'Error Creating Stripe Customer' }));
    }
  };
};

const updateSubscription = (response) => ({
  type: Types.UPDATE_STRIPE_SUBSCRIPTION,
  response,
});

const listStripePricingTiers = (response) => ({
  type: Types.LIST_STRIPE_PRICING_TIERS,
  response,
});

const updateStripeSubscriptions = (subId, field, value) => {
  // Field is the property to update on the stripe subscripton object  and value, the value to be updated
  return async (dispatch, getState) => {
    const state = getState();

    const stripeCustomerId = state.payment?.stripe?.stripeCustomerId || '';
    // subId is an array of subscription ids
    const subscriptionId = subId.length === 1 ? subId[0] : subId;

    if (stripeCustomerId) {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + `/payment/stripe/updateSubscription?customerId=${stripeCustomerId}&field=${field}&value=${value}`,
          JSON.stringify({ subscriptionId }),
          {
            Organisation: String(state.currentOrganisation),
          }
        )
      );
      console.log('updateStripeSubscriptions', response);
      if (field.includes('payment')) {
        // The update was regarding payment method, so we need to update the payment methods
        dispatch(getStripePaymentMethods());
      }
      dispatch(updateSubscription(response.data));
    } else {
      dispatch(toggleAlertMessage(true, 'Stripe Customer Id not found', 'error'));
    }
  };
};

const updateStripeCustomer = (field, value) => {
  // Field is the property to update on the stripe subscripton object and value, the value to be updated
  return async (dispatch, getState) => {
    const state = getState();

    const stripeCustomerId = state.payment?.stripe?.stripeCustomerId || '';

    if (stripeCustomerId) {
      const body = { stripeCustomerId, field, value };
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/payment/stripe/updateStripeCustomer`, JSON.stringify(body), {
          Organisation: String(state.currentOrganisation),
        })
      );
      console.log('updateStripeCustomer', response);

      if (field.includes('payment')) {
        // The update was regarding payment method, so we need to update the payment methods
        dispatch(getStripePaymentMethods());
      }
    } else {
      dispatch(toggleAlertMessage(true, 'Stripe Customer Id not found', 'error'));
    }
  };
};

const removePaymentMethod = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const stripeCustomerId = state.payment?.stripe?.stripeCustomerId || '';

    if (stripeCustomerId) {
      const response = await axios(
        await createSignedRequest('DELETE', apiURL + '/payment/stripe/removePaymentMethod', JSON.stringify({ id }), {
          Organisation: String(state.currentOrganisation),
        })
      );
      console.log('removePaymentMethod', response);
      const { success, message, error } = response.data || {};
      if (success) {
        dispatch(toggleAlertMessage(true, message, 'success'));
        dispatch(getStripePaymentMethods());
      } else {
        console.log('removePaymentMethod Error', error);
        dispatch(toggleAlertMessage(true, message, 'error'));
      }
    } else {
      dispatch(toggleAlertMessage(true, 'Stripe Customer Id not found', 'error'));
    }
  };
};

const getSubscriptionPrice = (priceId, quantity) => {
  return async (dispatch, getState) => {
    const state = getState();

    const response = await axios(
      await createSignedRequest(
        'GET',
        apiURL + `/payment/stripe/subscriptionPrice?priceId=${priceId}&quantity=${quantity}`,
        null,
        {
          Organisation: String(state.currentOrganisation),
        }
      )
    );
    console.log('getSubscriptionPrice', response);

    const { success, message, error } = response.data || {};
    if (success) {
      return response.data;
    } else {
      const errMsg = message ? message : error;
      console.log('getSubscriptionPrice Error', error);
      dispatch(toggleAlertMessage(true, errMsg, 'error'));
    }
  };
};

const getStripePricingTiers = () => {
  return async (dispatch) => {
    const response = await createUnsignedRequest('GET', apiURL + `/registration/stripePricingTiers`, null, {})
      .then((response) => {
        if (response.ok) return response.json();
      })
      .then((data) => dispatch(listStripePricingTiers(data)));

    return response;
  };
};

const Actions = {
  selectProduct,
  selectPrice,
  showSubscriptionModal,
  subscriptionPlan,
  registrationBillingDetails,
  getStripeCoupon,
  stripeCoupon,
  createStripeCheckoutSession,
  sendUserOrderForm,
  getStripeInvoices,
  savePricingBand,
  getStripeCustomerId,
  getStripePaymentMethods,
  getStripeIntent,
  updateStripeSubscriptions,
  removePaymentMethod,
  createStripeCustomer,
  setStripeCustomerId,
  getSubscription,
  getSubscriptionPrice,
  updateStripeCustomer,
  getStripePricingTiers,
};

export { Actions, Types };
