import React, { useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const colors = [
  '#1f9b38',
  '#111111',
  '#FFE711',
  '#FF9300',

  '#26bf45',
  '#737373',
  '#e6ce00',
  '#e68400',

  '#1e9536',
  '#595959',
  '#b3a000',
  '#b36700',

  '#156a26',
  '#404040',
  '#807300',
  '#804a00',

  '#6ae182',
  '#a6a6a6',
  '#ffed4d',
  '#ffb34d',

  '#0d4017',
  '#262626',
  '#4d4500',
  '#4d2c00',

  '#40d95f',
  '#8c8c8c',
  '#ffe81a',
  '#ff9e1a',
];

const ConsumptionFacilityChart = ({ chartOptions }) => {
  const ref = useRef(null);

  if (!chartOptions) return null;

  return (
    <tr className='chart-row'>
      <td colSpan='20'>
        <HighchartsReact ref={ref} highcharts={Highcharts} options={chartOptions} />
      </td>
    </tr>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { consumptionByEntityByFacility, years } = state.consumption;

  const { entities, facility, metric } = state;

  const facilities = facility && facility.list;

  const { entityID } = ownProps;

  const entity = _.find(entities, { id: entityID });

  const byFacility = consumptionByEntityByFacility[entityID][years[years.length - 1]];

  const facilityIDs = byFacility && Object.keys(byFacility);

  if (!facilities || !byFacility || facilityIDs.length <= 1) return {};

  const chartData = facilityIDs.map((facilityID) => {
    const sliceData = byFacility[facilityID];
    const facilityDetails = facilities && facilities.find(({ id }) => id === facilityID);

    return {
      ...sliceData,
      name: facilityDetails && facilityDetails.name,
      y: sliceData.co2e,
    };
  });

  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: `${entity.name} Facility Breakdown`,
    },
    tooltip: {
      formatter() {
        const perMetricData = [];

        metric.list.forEach((item) => {
          if (this.point[item.id]) {
            perMetricData.push(`${item.name}: ${this.point[item.id].toFixed(2)}`);
          }
        });

        return `<b>${this.key}</b><br/>CO2e: ${this.point.co2e.toFixed(2)}<br/>${perMetricData.join('<br/>')}`;
      },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
        showInLegend: true,
        colors,
      },
    },
    series: [
      {
        name: 'Brands',
        colorByPoint: true,
        data: _.orderBy(chartData, ['co2e', 'name'], ['desc', 'asc']),
      },
    ],
  };

  return {
    entity,
    chartOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConsumptionFacilityChart);
