import _ from 'lodash';
import { itemToOptions } from '../../components/UsageData/AddEditUsage';
import UsageHashStuff from '../../components/utils/UsageHashStuff';
import { Types } from '../actions/usageActions';

const defaultState = {
  usage: {},
};

const usageReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.USAGE_UPDATE: {
      const { payload } = action;

      const newState = _.cloneDeep(state);

      payload.forEach((u) => {
        const index = newState.usageData.findIndex((ug) => ug.id === u.id);
        if (index > -1) {
          // Update Item
          newState.usageData[index] = { ...u };
        } else {
          // New Item -> Push New Usage Object to the state
          newState.usageData.push(u);
        }
      });

      if (payload?.length > 0 && payload?.length < 3) {
        newState.usageDataModal = {
          ...newState.usageDataModal,
          prevDates: { dateStart: payload[0].dateStart, dateEnd: payload[0].dateEnd },
        };
      }

      return newState;
    }

    case Types.USAGE_PREVIOUS_DATES: {
      const { newUsage } = action;

      const newState = _.cloneDeep(state);

      newState.usageDataModal = {
        ...newState.usageDataModal,
        prevDates: { dateStart: newUsage.dateStart, dateEnd: newUsage.dateEnd },
      };

      return newState;
    }

    case Types.USAGE_DELETE: {
      const { payload } = action;

      const newState = _.cloneDeep(state);

      payload.forEach((id) => {
        const index = newState.usageData.findIndex((ug) => ug.id === id);
        if (index > -1) {
          // Update Item
          newState.usageData.splice(index, 1);
        }
      });

      return newState;
    }

    case Types.USAGE_UPDATE_TOTALS: {
      let newState = _.cloneDeep(state);

      let { response, flag } = action;

      if (flag) {
        response = newState.usageData;
      }

      if (response) {
        //simulating sql group by, makes an array of arrays.
        const groupBy = (objectArray, ...properties) => {
          return [
            ...Object.values(
              objectArray?.reduce((accumulator, object) => {
                const key = JSON.stringify(
                  properties.map(
                    (x) =>
                      // x == 'year' ? object.location.calendarYear.year :
                      object[x] || null
                  )
                );

                if (!accumulator[key]) {
                  accumulator[key] = [];
                }
                accumulator[key].push(object);
                return accumulator;
              }, {})
            ),
          ];
        };

        const groupedArrays = groupBy(response, ...UsageHashStuff.supportedFilters);

        //options obj to map the ids to string value and ids to name fields.
        let options = {};
        options.entity = newState.entities;
        options.subentity = newState.subentities;
        options.facility = newState.facility.list;
        options.metric = newState.metric.list;
        Object.keys(newState.metric.types)?.forEach((item) => (options[item] = newState.metric.types[item]));

        const optionKeys = Object.keys(options);

        newState.usageData = response.map((item) => {
          const idNameObj = {};
          optionKeys.forEach((option) => {
            //mapping entityName,subentityName,etc
            idNameObj[`${option}Name`] =
              _.find(options[option], { id: String(item?.[option]) })?.name ||
              _.find(options[option], { id: String(item?.[option]) })?.priceType ||
              null;
            //mapping entity,subentity, etc.
            idNameObj[option] = item?.[option] === null ? null : item?.[option];
          });
          return { ...item, hash: UsageHashStuff.makeHash(item), ...idNameObj, checked: false };
        });

        //mapping through each subarray to make one giant array called groupedtotals.
        const groupedTotals = [];
        groupedArrays.forEach((array) => {
          let usageValue = 0;
          let idNameObj = {};
          //for array we count the total usage and get the length of the array to get the count of
          //items.
          array.forEach((item) => {
            usageValue += parseFloat(item.usage);
          });
          //mapping thru the ids to string value and getting ids to names.
          optionKeys.forEach((option) => {
            //mapping entityName,subentityName,etc
            idNameObj[`${option}Name`] =
              _.find(options[option], { id: String(array[0]?.[option]) })?.name ||
              _.find(options[option], { id: String(array[0]?.[option]) })?.priceType ||
              null;
            //mapping entity,subentity, etc.
            idNameObj[option] = array[0]?.[option] === null ? null : String(array[0]?.[option]);
          });
          //to make the hash...

          groupedTotals.push({
            count: array.length,
            usageValue,
            endDate: array[0].endDate,
            userLabel: null,
            year: array[0].year,
            ...idNameObj,
            //hash id to identify usage data item link to groupedTotals
            hash: UsageHashStuff.makeHash(array[0]),
          });
        });
        newState.usageTotals = _.sortBy(groupedTotals, [
          'entityName',
          'subentityName',
          'facilityName',
          'seatName',
          'vehicleName',
          'fuelName',
          'productionName',
          'wasteName',
          'metricName',
          'year',
        ]);
      } else {
        newState.usageTotals = [];
        newState.usageData = [];
      }

      newState.usageProcessing = false;

      return newState;
    }

    case Types.USAGE_IMPORT_ANALYSIS: {
      let newState = _.cloneDeep(state);
      const { status, payload } = action;

      newState.importModal = {};
      const importModal = newState.importModal;
      if (!status) {
        delete newState.progress;
        delete newState.importModal;
      }
      if (payload) {
        importModal.importSuccesses = payload.successes;
        importModal.importErrors = payload.errors;
        importModal.errorLink = payload.errorLink;
        importModal.lastRowNumber = payload.lastRowNumber;
        importModal.templateError = payload?.error;
      } else {
        importModal.importSuccesses = 0;
        importModal.importErrors = [];
      }

      return newState;
    }

    case Types.USAGE_TOGGLE_STATUS: {
      let newState = _.cloneDeep(state);
      newState.usageProcessing = action.saving;

      return newState;
    }

    case Types.USAGE_SHOW_DATA_DELETE_MODAL: {
      const newState = _.cloneDeep(state);
      newState.usageDataDeleteModal = {
        status: action.status,
        item: action.item,
      };
      return newState;
    }

    case Types.USAGE_SHOW_DATA_MODAL: {
      const newState = _.cloneDeep(state);

      const { item } = action;

      const usageTypeKeys = Object.keys(newState?.metric?.types).reduce((acc, curr) => {
        if (item?.[curr]) {
          acc[curr] = item?.[curr];
        }
        return acc;
      }, {});

      const filteredItem = {
        id: item?.id || null,
        facility: item?.facility || null,
        entity: item?.entity || null,
        dateStart: item?.dateStart || null,
        dateEnd: item?.dateEnd || null,
        userLabel: item?.userLabel || null,
        subentity: item?.subentity || null,
        metric: item?.metric || null,
        usage: item?.usage ? String(item.usage) : null,
        waterTreatment: item?.waterTreatment || null,
        solarConsumed: item?.solarConsumed || null,
        fuel_mix: item?.fuel_mix || null,
        copy: item?.copy || null,
        organisation: item?.organisation || null,
        ...usageTypeKeys,
        //production: item?.production || null,
        //fuel: item?.fuel || null,
        //load: item?.load || null,
        //seat: item?.seat || null,
        //vehicle: item?.vehicle || null,
        //waste: item?.waste || null,
      };

      newState.usageDataModal = {
        status: action.status,
        item: filteredItem,
        prevDates: newState?.usageDataModal?.prevDates || {},
        adminPortal: action?.adminPortal || false,
      };

      // When Closing/Opening Modal Reset Usage Options
      if (newState?.usageOptions) delete newState?.usageOptions;
      if (newState?.gridSources) delete newState?.gridSources;
      if (newState?.conversionFactors) delete newState?.conversionFactors;
      if (newState?.filteredCF_UsageOpt) delete newState?.filteredCF_UsageOpt;
      if (newState?.progress) delete newState.progress;

      return newState;
    }

    case Types.USAGE_EDIT_MODAL: {
      const newState = _.cloneDeep(state);
      const newItem = { ...newState.usageDataModal.item };
      const { selectedInput, event } = action || {};
      const { name } = event || {};

      const usageTypeKeys = Object.keys(newState?.metric?.types);
      const fieldsToClearInputs = [...usageTypeKeys, 'subentity', 'entity'];

      //const clearInput = event?.action === 'clear' || !selectedInput;
      if (name === 'prevDates') {
        newItem['dateStart'] = selectedInput.dateStart;
        newItem['dateEnd'] = selectedInput.dateEnd;
      } else {
        newItem[name] = event?.action === 'clear' ? null : name === 'subentity' ? selectedInput?.value : selectedInput;
      }

      // Handling Usage Options
      if (usageTypeKeys.includes(name)) {
        if (!selectedInput) {
          // Usage Option Was Removed, Wipe Out Selected Usage Options
          usageTypeKeys.forEach((ut) => delete newItem?.[ut]);
          delete newState.filteredCF_UsageOpt;
        } else {
          //Checking index for which usage option was edited.
          const uOptIndex = Object.keys(newState?.usageOptions).findIndex((uo) => uo === name);

          // If the index is not 0 we need to keep current filtered factors. If it is 0 we take all the factors.
          newState.filteredCF_UsageOpt =
            uOptIndex !== 0 ? [...newState.filteredCF_UsageOpt] : [...newState?.conversionFactors];

          if (uOptIndex !== Object.keys(newState?.usageOptions).length) {
            // Changing the value of the Usage Option which is not the last in the hierarchy -> Need to reset the values from Usage Option below on hierarchy
            for (let i = uOptIndex + 1; i < Object.keys(newState?.usageOptions).length; i++) {
              delete newItem?.[Object.keys(newState?.usageOptions)[i]];
            }
          }

          // Filters the conversion factors by the usage options selected if the index is 0
          if (uOptIndex === 0)
            newState.filteredCF_UsageOpt = newState?.filteredCF_UsageOpt?.filter((cf) => cf[name] === newItem[name]);
        }
      }

      if (name === 'entity') {
        // Reset Usage Data Modal Item, when changing Entity
        delete newItem?.subentity;
        delete newState?.usageOptions;
        delete newState?.conversionFactors;
        delete newState?.filteredCF_UsageOpt;
        delete newItem?.metric;
      } else if (name === 'subentity') {
        // Set Metric automatically if there's just one metric attached to Sub Entity
        const oneMetric = selectedInput?.metric;
        if (selectedInput && (oneMetric?.length === 1 || !selectedInput?.metric.includes(newItem['metric']))) {
          newItem['metric'] = _.find(newState.metric?.list.map(itemToOptions), { value: oneMetric[0] })?.value;
        } else delete newItem.metric; // Subentity has been cleared, reset metric
      } else if (name === 'dateStart' || name === 'dateEnd') {
        if (!selectedInput) {
          newItem[name] = null;
        } //else newItem[name] = new Date(selectedInput.setHours((-1 * selectedInput.getTimezoneOffset()) / 60));
        else newItem[name] = selectedInput;
      } else if (name === 'metric') {
        // Metric has been changed, delete usgOpt inserted
        delete newState?.usageOptions;
      }

      if (name === 'entity' || name === 'subentity' || name === 'metric') {
        // Reset values when entity/subentity is changed
        delete newItem?.load;
        delete newItem?.fuel;
        delete newItem?.production;
        delete newItem?.seat;
        delete newItem?.vehicle;
        delete newItem?.waste;
        delete newState?.filteredCF_UsageOpt;
        usageTypeKeys.forEach((ut) => delete newItem?.[ut]);
        delete newItem?.location;
        delete newItem?.market;
        delete newItem?.price;
        delete newItem?.originLocation;
        delete newItem?.factorProvider;

      }

      if (fieldsToClearInputs.includes(name)) {
        // Reset values
        delete newItem?.usage;
        delete newItem?.waterTreatment;
        delete newItem?.solarConsumed;
        delete newItem?.fuel_mix;
      }

      newState.usageDataModal.item = newItem;

      return newState;
    }

    case Types.USAGE_UPLOAD_TYPE: {
      const newState = _.cloneDeep(state);
      newState.usageUploadType = action.payload;

      return newState;
    }

    case Types.USAGE_IMPORT_LINK: {
      let newState = _.cloneDeep(state);

      newState.importUploadLink = action.payload.url;
      newState.importUploadKey = action.payload.key;
      return newState;
    }

    case Types.USAGE_MY_UPLOADS: {
      const newState = _.cloneDeep(state);

      newState.myUploads = action.payload;

      return newState;
    }

    case Types.LIST_USAGE_DETAILS: {
      const newState = _.cloneDeep(state);

      newState.usageDataModal.usageDetails = newState.usageDataModal.usageDetails
        ? { ...newState.usageDataModal.usageDetails }
        : {};

      ['modifiedDate', 'location', 'market'].forEach((field) => {
        newState.usageDataModal.usageDetails[field] = action.payload[field];
        if (action.payload[field]?.['calendarYear'] || action.payload[field]?.['reportingYear']) {
          // Delete Conversion Factors Field From The Object
          delete action.payload[field]?.['calendarYear']?.['conversionFactors'];
        }
      });

      return newState;
    }

    case Types.USAGE_IMPORT_SHOW_MODAL: {
      const newState = _.cloneDeep(state);

      delete newState.alertMessage;

      newState.usageUploadModal = action.status;
      if (!action.status) {
        delete newState.usageUploadType;
        delete newState.progress;
      }
      return newState;
    }

    case Types.USAGE_IMPORT_SHOW_HISTORY: {
      const newState = _.cloneDeep(state);

      newState.usageUploadHistory = action.status;

      return newState;
    }

    case Types.USAGE_OPTIONS: {
      const newState = _.cloneDeep(state);
      const newItem = { ...newState.usageDataModal.item };
      const usageTypeDetails = newState.admin.adminPortal?.usageTypeDetails;
      const metricTypes = newState.metric.types;
      const { payload: { conversionFactors, gridSources } = {} } = action || {};

      // Get the available usage options for this conversion factors
      const availableUsageOptions = conversionFactors?.reduce((acc, cf) => {
        Object.keys(metricTypes).forEach((usgType) => {
          const availableUsageOption = cf[usgType];
          if (availableUsageOption) {
            // Get the specific option
            const usgOption = _.find(metricTypes[usgType], { id: availableUsageOption });
            if (usgOption && !acc[usgType]?.includes(usgOption)) {
              acc[usgType] = acc[usgType] || [];
              acc[usgType] = acc[usgType].length > 0 ? [...acc[usgType], usgOption] : [usgOption];
            }
          }
        });
        return acc;
      }, {});

      const checkFactorProviderUsageOpt = availableUsageOptions?.['factorprovider']?.length > 1;

      Object.keys(availableUsageOptions)?.map((ut) => {
        const { default: defaultValue } = usageTypeDetails?.find((usgDts) => usgDts.type === ut) || {};
        const savingNewUsage = !newItem.id;
        const factorProvideOpt = ut === 'factorprovider';

        // If it's a new item and there's a default value, we set that value in the item object
        if (defaultValue && savingNewUsage) {
          newItem[ut] = defaultValue;
        }
        // If we are editing, and there's more than one factorProvider option,
        // and the usageItem doesn't have a factorProvider, we set the default value
        else if (!savingNewUsage && checkFactorProviderUsageOpt && factorProvideOpt && !newItem[ut]) {
          const defaultFactorProvider = '52dc726d-14c5-4ed1-b94c-3d33f3fa75bd';
          newItem[ut] = defaultFactorProvider;
        }

        // Since a Usg Opt was added we need to filter the conversion factor array
        newState.filteredCF_UsageOpt = newState.filteredCF_UsageOpt
          ? [...newState.filteredCF_UsageOpt].filter((cf) => cf[ut] === newItem[ut])
          : [...conversionFactors].filter((cf) => cf[ut] === newItem[ut]);
        return null;
      });

      newState.usageOptions = availableUsageOptions;
      newState.usageDataModal.item = newItem;
      newState.gridSources = gridSources.filter((source) => source !== 'Residual');
      newState.conversionFactors = conversionFactors;

      return newState;
    }

    default:
  }
};

export default usageReducers;
