import { format, fromUnixTime, addYears, isValid } from 'date-fns';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Actions as PAYMENTACTIONS } from '../../store/actions/paymentActions';

const getCurrencySymbol = (currency) => {
  let subscriptionCurrency;
  switch (currency) {
    case 'eur':
      subscriptionCurrency = '€';
      break;
    case 'usd':
      subscriptionCurrency = '$';
      break;
    default:
      subscriptionCurrency = '£';
  }
  return subscriptionCurrency;
};

const WarningModal = ({
  lastSubscription,
  getSubscriptionPrice,
  setSubDetails,
  subDetails,
  isWhiteLabelApp,
  subEndDate,
}) => {
  const { current_period_start, current_period_end, plan, quantity, currency } = lastSubscription || {};
  const coupon = lastSubscription?.discount?.coupon || {};
  const convertedPeriodEnd = !lastSubscription
    ? format(new Date(subEndDate), 'dd/MM/yyyy')
    : format(fromUnixTime(current_period_end), 'dd/MM/yyyy');
  const convertedPeriodStart = !lastSubscription
    ? format(addYears(new Date(subEndDate), -1), 'dd/MM/yyyy')
    : format(fromUnixTime(current_period_start), 'dd/MM/yyyy');

  useEffect(() => {
    (async () => {
      if (lastSubscription) {
        let { subscriptionPrice: { flat_amount } = {} } = (await getSubscriptionPrice(plan.id, quantity)) || {};
        if (coupon?.valid) {
          flat_amount = flat_amount - (flat_amount * coupon.percent_off) / 100;
        }
        setSubDetails((prevState) => ({
          ...prevState,
          subPrice: flat_amount ? flat_amount / 100 : null,
          curr: getCurrencySymbol(currency),
        }));
      }
    })();
    //eslint-disable-next-line
  }, [lastSubscription]);

  return (
    <div>
      <div className='modal-body' style={{ overflow: 'auto', marginBottom: 20 }}>
        <p style={{ fontSize: 20, fontWeight: 'bold' }}>Attention: </p>
        <p style={{ fontSize: 14, textAlign: 'justify' }}>
          Your annual subscription runs from{' '}
          <span style={{ fontWeight: 'bold' }}>
            {convertedPeriodStart} to {convertedPeriodEnd}.
          </span>
          <br />
          This includes one report per year, which has already been generated. <br />
          <br />
          If you would like to generate another report before your current subscription and annual report allowance renews,
          you can pay for access to another annual year and can bring forward your subscription renewal date to today. <br />
          <br />
          To go ahead, please select your chosen reporting period and click 'Update subscription & Add a Report. The
          subscription fee,{' '}
          <span style={{ fontWeight: 'bold' }}>
            {subDetails?.curr}
            {subDetails.subPrice}
          </span>
          , will be processed using the card details you have saved. <br />
          <br /> You will then be able to generate the new report and will receive a receipt for this payment via Stripe sent
          to your registered email address.
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const isWhiteLabelApp = state.isWhiteLabelApp;
  const { currency } = state?.organisation?.details || {};
  const { stripeSubscriptions } = state?.payment?.stripe || {};
  const orgDetails = state?.organisation?.details;
  let lastSubscription;

  if (Array.isArray(stripeSubscriptions) && stripeSubscriptions.length >= 1) {
    // Get last subscription
    lastSubscription = _.orderBy(stripeSubscriptions, (sub) => fromUnixTime(sub.created), 'desc')?.[0];
  }

  return { currency, lastSubscription, isWhiteLabelApp, subEndDate: orgDetails?.subscriptionEndDate };
};

const mapDispatchToProps = (dispatch) => ({
  getSubscription: () => dispatch(PAYMENTACTIONS.getSubscription()),
  getSubscriptionPrice: (priceId, quantity) => dispatch(PAYMENTACTIONS.getSubscriptionPrice(priceId, quantity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WarningModal);
