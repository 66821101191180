import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import ACTIONS from '../../../store/action';
import { selectStyles } from '../../utils/ReactSelectStyles';
import { itemToOptions } from '../../utils/GlobalFunctions';
import Breadcrumb from '../../Common/Breadcrumb';
import { Box, Checkbox, FormControlLabel, LinearProgress, Radio, RadioGroup } from '@mui/material';
import { AddButton } from '../../../styledComponents/GlobalStyle';
import _ from 'lodash';
import Select from 'react-select';
import * as yup from 'yup';

const items = [{ label: 'Transfer Portal', link: '/admin/transferPortal' }];
const boxStyles = { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' };

const TransferPortalModal = ({
  setShowModal,
  state,
  transferOrg,
  showLoader,
  alertMessage,
  setState,
  toggleAlertMessage,
}) => {
  const transferFinished = alertMessage === 'Finished' ? true : false;

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button onClick={() => setShowModal(false)} className='close'>
              x
            </button>
            <h4 className='modal-title'>Transfer Portal</h4>
          </div>
          <div className='modal-body'>
            <h5>
              {alertMessage
                ? 'Status: ' + alertMessage
                : `Are you sure you want to copy all data from ${state.fromName} to ${state.toName}?`}
            </h5>

            <div style={{ display: showLoader ? 'block' : 'flex' }}>
              {!showLoader ? (
                ['Proceed', 'Cancel', 'Close Modal'].forEach((item, index) => {
                  const styles = { marginLeft: index && '1em', marginTop: '1em' };
                  // Don't show Close Modal btn until transfer is finished
                  if (!transferFinished && index === 2) return;
                  else if (transferFinished && (index === 0 || index === 1)) return;
                  return (
                    <AddButton
                      key={index}
                      className='btn btn-success'
                      style={transferFinished ? { marginTop: '1em' } : styles}
                      onClick={() => {
                        if (transferFinished) {
                          // Transfer is Finished, reset the state and close the modal
                          setState({ createNewOrg: false });
                          // Reset alertMessage
                          toggleAlertMessage(false, '');
                          // Close the Modal
                          setShowModal(false);
                        } else {
                          if (!index) {
                            if (state.clientAdministrator === 'directUser') {
                              // If clientAdministrator is directUser, delete it from the state before transfering organisation
                              delete state.clientAdministrator;
                            }
                            transferOrg(state);
                          } else setShowModal(false);
                        }
                      }}>
                      {item}
                    </AddButton>
                  );
                })
              ) : (
                <LinearProgress sx={{ marginTop: '1.5em', marginBottom: '1em' }} color='inherit' />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TransferPortal = ({
  transferPortalOptions,
  adminOrgs,
  transferOrg,
  showLoader,
  alertMessage,
  toggleAlertMessage,
  adminOrgs2,
  schema,
}) => {
  const [state, setState] = useState({ createNewOrg: false });
  const [showModal, setShowModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  const validate = async (state) => {
    await schema
      .validate(state, { abortEarly: false })
      .then(() => setDisableBtn(false))
      .catch(() => setDisableBtn(true));
  };

  const updateState = async (e) => {
    const newState = { ...state };
    const { name, type, value, checked } = e.target;
    if (type === 'checkbox') {
      if (['createNewOrg'].includes(name)) {
        // Reset the state when check/uncheck createNewOrg
        if (['to', 'toName'].some((x) => newState.hasOwnProperty(x))) {
          delete newState.to;
          delete newState.toName;
        }

        if (checked) {
          // Set Default Value for Testing Org when createNewOrg is checked
          newState.testOrg = 'None';
        } else delete newState.testOrg;

        if (['clientAdministrator'].some((x) => newState.hasOwnProperty(x))) {
          delete newState.clientAdministrator;
          ['whiteLabel', 'consultant'].forEach((x) => delete newState?.[x]);
        } else newState.clientAdministrator = 'directUser';
      }
      newState[name] = checked;
    } else if (type === 'text') {
      newState[name] = value;
      newState[`${name}Name`] = value;
    } else if (type === 'radio') {
      ['whiteLabel', 'consultant'].forEach((x) => delete newState?.[x]);
      newState[name] = value;
    }
    setState(newState);
    await validate(newState);
  };

  if (state.createNewOrg) {
    // createNewOrg is true, change the HTML element to an input field to set orgName
    transferPortalOptions['to'] = { type: 'text', title: 'New Org', icon: 'fas fa-warehouse' };
  } else {
    // createNewOrg is false, change the HTML element to an select field to select the To org
    const toOptions = state.from ? _.filter(adminOrgs, (org) => org.value !== state.from) : adminOrgs;
    transferPortalOptions['to'] = { type: 'select', title: 'To', options: toOptions, icon: 'fas fa-warehouse' };
  }

  if (['whiteLabel', 'consultant'].includes(state.clientAdministrator)) {
    // In case  of whiteLabel or consultant, show clientAdministratorOwnership and set the options
    transferPortalOptions['clientAdministratorOwnership'].options = adminOrgs2
      .filter((x) => {
        if (['whiteLabel'].includes(state.clientAdministrator)) {
          return x.id === x.whiteLabelId;
        } else return x.id === x.consultantId;
      })
      .map(itemToOptions);
  }

  return (
    <>
      <Box sx={boxStyles}>
        <Breadcrumb items={items} />
      </Box>

      <div className='row'>
        <div className='col-lg-12'>
          <section className='panel'>
            <aside className='profile-nav alt'>
              <section className='panel'>
                <header className='panel-heading'> Transfer Portal</header>
                <ul className='nav nav-pills nav-stacked'>
                  {Object.keys(transferPortalOptions).map((key, index) => {
                    const type = transferPortalOptions[key].type;
                    const options = transferPortalOptions[key].options;
                    const ownershipState = state['clientAdministrator'];
                    const arr = ['whiteLabel', 'consultant'];
                    let value;

                    if (!state['createNewOrg']) {
                      // Not Creating New Org, Hide Testing Org Field and Client Administrator Type
                      if (['testOrg', 'clientAdministrator', 'clientAdministratorOwnership'].includes(key)) return null;
                    } else {
                      if (['clientAdministratorOwnership'].includes(key) && !arr.includes(ownershipState)) {
                        // Don't show clientAdministratorOwnership if whiteLabel or consultant is not selected
                        return null;
                      }
                    }

                    if (type === 'select') {
                      if (['from', 'to'].includes(key)) {
                        value = _.find(options, { value: state[key] });
                      } else if (['clientAdministratorOwnership'].includes(key)) {
                        if (state['whiteLabel']) value = _.find(options, { value: state['whiteLabel'] });
                        else value = _.find(options, { value: state['consultant'] });
                      }
                    }

                    return (
                      <li key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px 15px',
                          }}>
                          <div>
                            <i className={transferPortalOptions[key].icon} style={{ paddingRight: 20 }} />
                            {transferPortalOptions[key].title}
                          </div>
                          {type === 'select' && (
                            <Select
                              styles={{ ...selectStyles, container: (provided) => ({ ...provided, width: '15vw' }) }}
                              options={options}
                              isClearable={true}
                              value={value || null}
                              onChange={async (selected) => {
                                const newState = { ...state };
                                if (!selected) {
                                  delete newState[key];
                                  delete newState[`${key}Name`];
                                } else if (key === 'clientAdministratorOwnership') {
                                  if (state['clientAdministrator'] === 'whiteLabel') {
                                    newState['whiteLabel'] = selected.value;
                                  } else newState['consultant'] = selected.value;
                                } else {
                                  newState[key] = selected.value;
                                  newState[`${key}Name`] = selected.label;
                                }
                                setState(newState);
                                await validate(newState);
                              }}
                            />
                          )}
                          {type === 'text' && (
                            <input
                              style={{ border: 'solid 1px rgba(0,0,0,.05)', width: '15vw', height: 48, padding: 8 }}
                              name={key}
                              onChange={updateState}
                            />
                          )}
                          {type === 'checkbox' && <Checkbox checked={state[key]} onChange={updateState} name={key} />}
                          {type === 'radio' && (
                            <RadioGroup row value={state[key]} onChange={updateState} name={key}>
                              {options.map((x, i) => (
                                <FormControlLabel key={i} control={<Radio />} value={x.name} label={x.title} />
                              ))}
                            </RadioGroup>
                          )}
                          {type === 'button' && (
                            <AddButton
                              className='btn btn-success'
                              onClick={() => setShowModal(true)}
                              disabled={disableBtn ? true : false}>
                              {transferPortalOptions[key].children}
                            </AddButton>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </section>
            </aside>
          </section>
        </div>
      </div>
      {showModal && (
        <TransferPortalModal
          setShowModal={setShowModal}
          state={state}
          setState={setState}
          transferOrg={transferOrg}
          showLoader={showLoader}
          alertMessage={alertMessage}
          toggleAlertMessage={toggleAlertMessage}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const adminOrgs = _.sortBy(state.admin?.organisations, ['name']).map(itemToOptions);
  const transferPortalOptions = {};
  transferPortalOptions.from = { type: 'select', title: 'From', options: adminOrgs, icon: 'fas fa-warehouse' };
  transferPortalOptions.to = { type: 'select', title: 'To', options: adminOrgs, icon: 'fas fa-warehouse' };
  transferPortalOptions.createNewOrg = { type: 'checkbox', title: 'Create New Org', icon: 'fas fa-plus-circle' };
  transferPortalOptions.testOrg = {
    type: 'radio',
    title: 'Test Organisation',
    icon: 'fa fa-book',
    options: [
      { title: 'CYF Test Organisation', name: 'CYF Test Organisation' },
      { title: 'Client Test Organisation', name: 'Client Test Organisation' },
      { title: 'None', name: 'None' },
    ],
  };
  transferPortalOptions.clientAdministrator = {
    type: 'radio',
    title: 'Client Administrator Type',
    icon: 'fa fa-book',
    options: [
      { title: 'White Label', name: 'whiteLabel' },
      { title: 'Consultant', name: 'consultant' },
      { title: 'Direct User', name: 'directUser' },
    ],
  };
  transferPortalOptions.clientAdministratorOwnership = {
    type: 'select',
    title: 'Client Administrator',
    icon: 'fa fa-book',
    options: [],
  };
  transferPortalOptions.button = { type: 'button', children: 'Transfer Organisation' };

  const schema = yup.object().shape({
    from: yup.string().required('From Organisation is required'),
    to: yup.string().required('To Organisation is required'),
    createNewOrg: yup.bool().notRequired(),
    testOrg: yup.string().when('createNewOrg', {
      is: (val) => val === true,
      then: yup.string().required('Test Org is required'),
      otherwise: yup.string().notRequired(),
    }),
    clientAdministrator: yup.string().when('createNewOrg', {
      is: (val) => val === true,
      then: yup.string().required('Client Administrator Type is required'),
    }),
    consultant: yup.string().when('clientAdministrator', {
      is: (val) => val === 'consultant',
      then: yup.string().required('Please Select Consultant Admin'),
    }),
    whiteLabel: yup.string().when('clientAdministrator', {
      is: (val) => val === 'whiteLabel',
      then: yup.string().required('Please Select White Label Admin'),
    }),
  });

  return {
    alertMessage: state.alertMessage?.message,
    showLoader: state.showLoader,
    transferPortalOptions,
    adminOrgs,
    adminOrgs2: state.admin?.organisations,
    schema,
  };
};

const mapDispatchToProps = (dispatch) => ({
  transferOrg: (state) => dispatch(ADMINACTIONS.transferOrg(state)),
  toggleAlertMessage: (status, message) => dispatch(ACTIONS.toggleAlertMessage(status, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferPortal);
