import React from 'react';
import { Actions as USAGEACTIONS } from '../store/actions/usageActions';
import { connect } from 'react-redux';
import UsageData from '../components/UsageData/UsageData';
import Breadcrumb from '../components/Common/Breadcrumb';

const Usage = ({ organisation, getUsageTotals, processing, branding, industriesList }) => {
  const { headers } = branding?.fonts?.[0]?.font;

  return (
    <React.Fragment>
      <Breadcrumb items={[{ label: 'Usage', link: '/usage' }]} />

      <div className='row' style={{ fontFamily: headers }}>
        <div className='col-lg-12'>
          <section className='panel'>
            <aside className='profile-nav alt'>
              <section className='panel'>
                <header className='panel-heading'>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    Usage data - {organisation?.name}
                    {!processing && (
                      <i
                        id='refreshUsage'
                        className={'fa fa-refresh'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => getUsageTotals()}
                      />
                    )}
                    {processing && <i className='fa fa-spin fa-spinner' />}
                  </div>
                </header>
                <ul className='nav nav-pills nav-stacked'>
                  <li>
                    <div style={{ padding: '10px 15px' }}>
                      <i style={{ paddingRight: '10px' }} className='fa fa-calendar'></i> Reporting year end{' '}
                      <span style={{ float: 'right' }}>
                        {organisation?.reportingYearEndDay}/{organisation?.reportingYearEndMonth}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div style={{ padding: '10px 15px' }}>
                      <i style={{ paddingRight: '10px' }} className='fa fa-bookmark'></i> Industry sector
                      <span style={{ float: 'right' }}>
                        {industriesList?.find((i) => i.id === organisation?.industry)?.name}
                      </span>
                    </div>
                  </li>
                </ul>
              </section>
            </aside>
          </section>
        </div>
      </div>

      <UsageData />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state.organisation?.details,
    industriesList: state.organisation?.industries,
    processing: state.usageProcessing,
    branding: state.branding,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUsageTotals: () => dispatch(USAGEACTIONS.getUsageTotals()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Usage);
